import React, {useState, useEffect} from "react";
import {Box, useBreakpointValue,Flex, HStack, IconButton} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIcons, faCircleArrowUp, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { color } from "framer-motion";

const Footer = () => {

// Creating a visible up icon upon scroll up
let [scrollingUp, setScrollingUp] = useState(true)
let [initialScroll, setInitialscroll] = useState(0)
  
    useEffect((e) => {

      let handleScroll = () => {
        let verticalScroll = window.scrollY
        
        setScrollingUp(verticalScroll < initialScroll)
        setInitialscroll(verticalScroll)
      }
      
      document.addEventListener("scroll", handleScroll)
      
      return () => {
        document.removeEventListener("scroll", handleScroll)
      }
    })

  
    
    let mobileView = useBreakpointValue({
      base: true,
      md: false
    })
  

  
    let style = {
    position: "fixed",
    bottom: "0",
    marginBottom: mobileView? 20 :10
  }



  
  return (
    <Box backgroundColor="#18181b" maxWidth="full">
      <footer>
        <Flex
          margin="0 auto"
          px={12}
          color="white"
          justifyContent="center"
          alignItems="center"
          maxWidth="1024px"
          height={16}
          paddingTop={10}
          paddingBottom={7}
        >
        {/*  <p>Ben • © 2023</p> */}
        <p>© 2024 Ben</p>

 
        </Flex>
        
        {scrollingUp && 
        <Box box-shadow="md"  >
        <HStack justifyContent="end" paddingRight={20} >


      <div class="iconbutton">
        <a onClick={(e)=>{
          window.scrollTo({
            top: 0,
            behaviour: true
          })

        }}>

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" width="63px">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        
        </a>
      </div>


   {/*     <IconButton icon={<FontAwesomeIcon icon={faAngleUp} cursor="pointer" size="2x" color="blue" />} border="3px solid blue" isRound={true} zIndex={1} onClick={(e) => {
            let element = document.getElementById("homepage-section")
            if(element){
              return element.scrollIntoView({
                behaviour:"smooth",
                block: "start"
              })
            }
            else{
              return;
            }
          }} style={style}></IconButton>  
    */}

        </HStack>
        </Box>}
      </footer>
    </Box>
  );
};
export default Footer;
