import React, {useEffect, useRef, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faQuoteRight, faQuoteLeft, faStarHalf, faArrowAltCircleRight, faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import {useBreakpointValue,Box, Avatar, Container,HStack, Center,Heading,Image,VStack, SimpleGrid, Card, Text, Link,CardHeader, CardBody, LinkBox, LinkOverlay, CardFooter, IconButton, ButtonGroup, Flex} from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Slider from "react-slick"
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import customer1 from "../Assets/customer1.jpg"
import customer2 from "../Assets/customer2.jpg"
import customer3 from "../Assets/customer3.jpg"
import customer4 from "../Assets/customer4.jpg"
import customer5 from "../Assets/customer5.jpg"
import customer6 from "../Assets/customer6.jpg"
import customer7 from "../Assets/customer7.jpg"
import customer8 from "../Assets/customer8.jpg"









import {useSpring ,animated} from "react-spring"
//import { useInView } from "react-spring";
import {useInView} from 'react-intersection-observer'





function Testimonial({toggleProp, setToggle}){

let[visible1, setVisible1] = useState(false)
let[visible2, setVisible2] = useState(false)
let[visible3, setVisible3] = useState(false)
let[visible4, setVisible4] = useState(false)
let[visible5, setVisible5] = useState(false)
let[visible6, setVisible6] = useState(false)



let {ref: ref1, inView:inView1} = useInView({triggerOnce: true})
let {ref: ref2, inView: inView2} = useInView({triggerOnce: true})
let {ref :ref3, inView:inView3} = useInView({triggerOnce: true})
let {ref :ref4, inView:inView4} = useInView({triggerOnce: true})
let {ref :ref5, inView:inView5} = useInView({triggerOnce: true})
let {ref :ref6, inView:inView6} = useInView({triggerOnce: true})




let animation1 = useSpring ({
 // to: {opacity: 1, transform: "translateY(0px)"},
  //from: {opacity: 0, transform: "translateY(100px)"},
  opacity: visible1? 1:0,
  transform: visible1? "translateY(0px)": "translateY(100px)",
  config: {tension: 200, friction: 20, duration: 1000}
})


let animation2 = useSpring ({
   opacity: visible2? 1:0,
   transform: visible2? "translateY(0px)": "translateY(100px)",
   config: {tension: 200, friction: 20, duration: 1000}
 })

 
let animation3 = useSpring ({
   opacity: visible3? 1:0,
   transform: visible3? "translateY(0px)": "translateY(100px)",
   config: {tension: 200, friction: 20, duration: 1000}
 })
 
let animation4 = useSpring ({
  opacity: visible4? 1:0,
  transform: visible4? "translateY(0px)": "translateY(100px)",
  config: {tension: 200, friction: 20, duration: 1000}
})

let animation5 = useSpring ({
  opacity: visible5? 1:0,
  transform: visible5? "translateY(0px)": "translateY(100px)",
  config: {tension: 200, friction: 20, duration: 1000}
})

let animation6 = useSpring ({
  opacity: visible6? 1:0,
  transform: visible6? "translateY(0px)": "translateY(100px)",
  config: {tension: 200, friction: 20, duration: 1000}
})


useEffect((e)=>{
 
  if(inView1){
    setVisible1(true)
    }
},[inView1])


useEffect((e)=>{
 
  if(inView2){
    setVisible2(true)
    }
},[inView2])


useEffect((e)=>{
 
  if(inView3){
    setVisible3(true)
    }
},[inView3])


useEffect((e)=>{
 
  if(inView4){
    setVisible4(true)
    }
},[inView4])


useEffect((e)=>{
 
  if(inView5){
    setVisible5(true)
    }
},[inView5])


useEffect((e)=>{
 
  if(inView6){
    setVisible6(true)
    }
},[inView6])



let [index, setIndex] = useState(0)

// We're using 0 and 1 because array index starts at 0. 
// Also the selectedItem prop accepts only index of the slide.
let handleNext =(e)=>{
  setIndex(prevIndex => prevIndex === 0? prevIndex +1 : 0)
}


let handlePrev =(e)=>{
  setIndex(prevIndex => prevIndex === 0? 1:prevIndex -1 )
}


let slideRef = useRef(null)

let next =(e)=>{
  slideRef.current.slickNext()
}

let prev =(e)=>{
  slideRef.current.slickPrev()
}

let mobileView = useBreakpointValue({
  base: true,
  md: false
})


let tabletView = useBreakpointValue({
  md: true, 
  lg: false
})



if (mobileView){
  return(
    <div>
        <VStack maxWidth="full" padding={10} alignItems="left" spacing={8} backgroundColor={toggleProp? "#18181b":"#2A4365"} color="white" >
          
       
          <Heading as="h2" size="2xl" color="white" id="services-section">Testimonials</Heading>
          
          <ButtonGroup >
          <IconButton children={<FontAwesomeIcon icon={faArrowAltCircleLeft} size="2xl" />} onClick={prev} rounded="full" colorScheme="purple" />
          <IconButton children={<FontAwesomeIcon icon={faArrowAltCircleRight} size="2xl" /> } onClick={next} rounded="full" color="purple"  />
          </ButtonGroup>
  
          
          <Text fontSize="xl" style={{margin: 5, paddingTop: 20}}> Explore testimonials from delighted clients and discover what others have to say about their experiences working with me. 
          I invite you to explore and find out why my clients are my biggest advocates. Their words speak volumes about the dedication, professionalism
          and results-driven approach I bring to every project.
          </Text> 
          
      
        <Box ml={-5} mr={-5} >
        <Slider ref={slideRef} fade arrows={false}  speed={1000} >
         
          <Card borderRadius={10} bg={toggleProp && "#18181b"} cursor="pointer" boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >
            
            <CardHeader p={8} >
            <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="md" >
            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>Ben's</span> work exceeded my expectations! His attention to detail and creative approach made my website stand out.
            I highly recommend him for anyone looking to enhance their online presence. Working with him was a breeze and I'm grateful for his <span style={{marginRight: 5}}> expertise. </span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>
          
            </CardHeader>

            <CardBody p={8} pt={-8} >
            <VStack mt={-8}  alignItems="start">
            < div>
            <Image src={customer4} boxSize="150px" borderRadius={8} mt={5} size="2xl"/>
            </div>
            <VStack alignItems="start">
            <Heading as="h3" size="md" mt={2} color={toggleProp && "white"}>Brian Will </Heading> 

            <HStack spacing={0} >
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStarHalf} color="#F4CE14"/>
            </HStack>

            <Text color={toggleProp? "white": "#64748b"} >Stanbic CEO</Text>
            </VStack>
            </VStack>
            
            </CardBody>
          </Card>


          <Card borderRadius={10} alignItems="start" bg={toggleProp && "#18181b"} cursor="pointer" boxShadow= "20px 0px 20px rgba(0,0,0,0.5)">
            
            <CardHeader p={8} >
            <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="md" >
            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>I'm </span>thrilled with the results Ben delivered for my project. His expertise and professionalism made the process
            smooth and enjoyable, I'll definitely be working with him again in the future. His dedication to understanding my needs truly made a <span style={{marginRight: 5}}> difference.</span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>
          
            </CardHeader>

           <CardBody p={8} pt={-8} ml={-5}>
            <VStack alignItems="start" mt={-8}>
            <Box ml={5}>
            <Image src={customer1} boxSize="150px" borderRadius={8} mt={5} size="2xl"/>
            </Box>
            <VStack alignItems="start" ml={ 5}>
            <Heading as="h3" size="md" mt={2} color={toggleProp && "white"}>Mwamba Rollands  </Heading> 

            <HStack spacing={0} >
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            </HStack>
              
            <Text color={toggleProp? "white": "#64748b"} >Manager, Zesco</Text>
            </VStack>
            </VStack>
            
            </CardBody>
          </Card>

        
        <Card borderRadius={10} alignItems="start" bg={toggleProp && "#18181b"} cursor="pointer" boxShadow= "20px 0px 20px rgba(0,0,0,0.5)">
            
            <CardHeader p={8}>
            <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="md" >
            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>Ben </span> is a true master of his craft. His ability to understand my vision and bring it to life 
            was impressive. I couldn't be happier with the outcome of our collaboration. Additionally, his friendly demeanor made the entire process<span style={{marginRight: 5}}> enjoyable.</span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>
        
            </CardHeader>

            <CardBody p={8} pt={-8}  >
            <VStack mt={-8} alignItems="start">
            <Box >
            <Image src={customer2}  boxSize="150px" borderRadius={8} mt={5} size="2xl"/>
            </Box>
            <VStack alignItems="start" >
            <Heading as="h3" size="md" mt={2} color={toggleProp && "white"}>Arthur James </Heading> 
            
            <HStack spacing={0} >
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStarHalf} color="#F4CE14"/>

            </HStack>
            <Text color={toggleProp? "white": "#64748b"} >Marketing Manager, Itel Inc.</Text>
            </VStack>
            </VStack>
            
            </CardBody>
        </Card>

          
        <Card borderRadius={10} alignItems="start"  bg={toggleProp && "#18181b"} cursor="pointer" boxShadow= "20px 0px 20px rgba(0,0,0,0.5)">
            
            <CardHeader p={8}>
            <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="md" >

            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>Working </span> with Ben was a breath of fresh air. His dedication to delivering high-quality results, coupled
            with his excellent communication skills, made him a pleasure to work with. Highly recommended! Furthermore, his
            flexibility in adapting to changes was<span style={{marginRight: 5}}> invaluable.</span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>
      
            </CardHeader>

           <CardBody p={8} pt={-8} >
            <VStack mt={-8} alignItems="start">
            <div>
            <Image src={customer3} boxSize="150px" borderRadius={8} mt={5} size="2xl"/>
            </div>
            <VStack alignItems="start" >
            <Heading as="h3" size="md" mt={2} color={toggleProp && "white"}>Mia Williams </Heading> 
            
            <HStack spacing={0} >
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>

            </HStack>
            <Text color={toggleProp? "white": "#64748b"} >Operations, Grew Media</Text>
            </VStack>
            </VStack>
            
            </CardBody>
          </Card>

          <Card alignItems="start" borderRadius={10} bg={toggleProp && "#18181b"} cursor="pointer" boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >
            
            <CardHeader p={8}  >
            <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="md" >
            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>I'm</span> so grateful for Ben's expertise and guidance throughout our project. His commitment to delivering on time and
            within budget was commendable. I look forward to partnering with him again. His professionalism and reliability are<span style={{marginRight: 5}}> unmatched.</span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>
          
            </CardHeader>

            <CardBody p={8} pt={-8} >
            <VStack mt={-8} alignItems="start">
            < div>
            <Image src={customer5} boxSize="150px" borderRadius={8} mt={5} size="2xl"/>
            </div>
            <VStack alignItems="start" >
            <Heading as="h3" size="md" mt={2} color={toggleProp && "white"}>Josh Roberts </Heading> 

            <HStack spacing={0} >
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStarHalf} color="#F4CE14"/>
            </HStack>

            <Text color={toggleProp? "white": "#64748b"} >SEO Specialist, E-commerce Ventures LLC.</Text>
            </VStack>
            </VStack>
            
            </CardBody>
          </Card>


          <Card borderRadius={10} alignItems="start" bg={toggleProp && "#18181b"} cursor="pointer" boxShadow= "20px 0px 20px rgba(0,0,0,0.5)">
            
            <CardHeader p={8} >
            <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="md" >
            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>Ben's</span> professsionalism and attention to detail set him apart from the rest. His passion for
            his work is evident in the exceptional results he delivers. I wouldn't hesitate to recommended him to anyone. Moreover, his creative solutions exceeded my<span style={{marginRight: 5}}> expectations.</span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>
          
            </CardHeader>

           <CardBody p={8} pt={-8}>
            <VStack alignItems="start" mt={-8}>
            <Box >
            <Image src={customer6} boxSize="150px" borderRadius={8} mt={5} size="2xl"/>
            </Box>
            <VStack alignItems="start" >
            <Heading as="h3" size="md" mt={2} color={toggleProp && "white"}>James Thompson </Heading> 

            <HStack spacing={0} >
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            </HStack>
              
            <Text color={toggleProp? "white": "#64748b"} >Project Coordinator, Global Solutions Ltd.</Text>
            </VStack>
            </VStack>
            
            </CardBody>
          </Card>

        
        <Card borderRadius={10} alignItems="start" bg={toggleProp && "#18181b"} cursor="pointer" boxShadow= "20px 0px 20px rgba(0,0,0,0.5)">
            
            <CardHeader p={8} >
            <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="md" >
            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>Ben's</span> creativity and technical skills are unmatched. He transformed my ideas into a visually stunning
            website that exceeded my expectations. I could'nt be happier with the end result. His ability to troubleshoot and find solutions was<span style={{marginRight: 5}}> impressive.</span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>
        
            </CardHeader>

            <CardBody p={8} pt={-8} >
            <VStack mt={-8} alignItems="start">
            <Box >
            <Image src={customer7}  boxSize="150px" borderRadius={8} mt={5} size="2xl"/>
            </Box>
            <VStack alignItems="start" >
            <Heading as="h3" size="md" mt={2} color={toggleProp && "white"}>Michael Patel </Heading> 
            
            <HStack spacing={0} >
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStarHalf} color="#F4CE14"/>

            </HStack>
            <Text color={toggleProp? "white": "#64748b"} >Senior Analyst, Financial Services Group</Text>
            </VStack>
            </VStack>
            
            </CardBody>
        </Card>

          
        <Card borderRadius={10} alignItems="start"  bg={toggleProp && "#18181b"} cursor="pointer" boxShadow= "20px 0px 20px rgba(0,0,0,0.5)">
            
            <CardHeader p={8}>
            <Text size="3xl" color={toggleProp? "white": "#64748b"} fontSize="md" >

            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>Working</span> with Ben was a game-changer for my business. His innovative solutions and strategic thinking helped
            us achieve our goals. I can't recommend him highly enough! His dedication to understanding our industry was evident in his <span style={{marginRight: 5}}> work.</span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>
      
            </CardHeader>

           <CardBody p={8} pt={-8}>
            <VStack mt={-8} alignItems="start">
            <div>
            <Image src={customer8} boxSize="150px" borderRadius={8} mt={5} size="2xl"/>
            </div>
            <VStack alignItems="start">
            <Heading as="h3" size="md" mt={2} color={toggleProp && "white"}>Alex Smith </Heading> 
            
            <HStack spacing={0} >
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>

            </HStack>
            <Text color={toggleProp? "white": "#64748b"} >Marketing Manager, Tech Innovations Inc</Text>
            </VStack>
            </VStack>
            
            </CardBody>
          </Card>
                
        
        
        </Slider>         
        </Box>

        </VStack>
        

    </div>
  )
}



else{

  return(
    <div>
        <VStack  padding={!tabletView && 10} maxWidth="full"  alignItems="left" spacing={8} backgroundColor={toggleProp? "#18181b":"#2A4365"} color="white" >
          
       
        <HStack padding={tabletView && 10} paddingBottom={tabletView && -10} width="100%" justifyContent="space-between" >
        <Heading as="h2" size="2xl" color="white" id="services-section">Testimonials</Heading>
        
        <ButtonGroup>
        <IconButton children={<FontAwesomeIcon icon={faArrowAltCircleLeft} size="2xl" />} onClick={handlePrev} rounded="full" colorScheme="purple" />
        <IconButton children={<FontAwesomeIcon icon={faArrowAltCircleRight} size="2xl" /> } onClick={handleNext} rounded="full" color="purple"  />
        </ButtonGroup>

        </HStack>
      
              
        <Text padding={tabletView && 10} fontSize="xl" style={{margin: 5, paddingTop: 20}}> Explore testimonials from delighted clients and discover what others have to say about their experiences working with me. 
        I invite you to explore and find out why my clients are my biggest advocates. Their words speak volumes about the dedication, professionalism
        and results-driven approach I bring to every project.
        </Text>
       
        
        <Carousel showArrows={false} showIndicators={false} selectedItem={index}>
        <SimpleGrid paddingRight={10} paddingLeft={10} columns={{md: 2, lg:2}} gap={8} >
          
          <Card borderRadius={10} bg={toggleProp && "#18181b"} cursor="pointer" boxShadow= "20px 0px 20px rgba(0,0,0,0.5)">
            
            <CardHeader p={8} >
            <Text align="start" size="3xl" color={toggleProp? "white": "#64748b"} fontSize="md" >
            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>Ben's</span> work exceeded my expectations! His attention to detail and creative approach made my website stand out.
            I highly recommend him for anyone looking to enhance their online presence. Working with him was a breeze and I'm grateful for his <span style={{marginRight: 5}}> expertise. </span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>
          
            </CardHeader>

            <CardBody p={8} pt={-8} marginRight={tabletView && "auto"} >
            <HStack flexDirection={tabletView  && "column"} mt={-8}>
            < div>
            <Image src={customer4} boxSize="150px" objectFit="cover" borderRadius={8} mt={5} size="2xl"/>
            </div>
            <VStack alignItems="start" ml={tabletView? -10: 5}>
            <Heading as="h3" size="md" mt={2} color={toggleProp && "white"}>Brian Will </Heading> 

            <HStack spacing={0} >
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStarHalf} color="#F4CE14"/>
            </HStack>

            <Text color={toggleProp? "white": "#64748b"} >Stanbic CEO</Text>
            </VStack>
            </HStack>
            
            </CardBody>
          </Card>


            <Card borderRadius={10} bg={toggleProp && "#18181b"} cursor="pointer" boxShadow= "20px 0px 20px rgba(0,0,0,0.5)">
            
            <CardHeader p={8} >
            <Text align="start" size="3xl" color={toggleProp? "white": "#64748b"} fontSize="md" >
            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>I'm </span>thrilled with the results Ben delivered for my project. His expertise and professionalism made the process
            smooth and enjoyable, I'll definitely be working with him again in the future. His dedication to understanding my needs truly made a <span style={{marginRight: 5}}> difference.</span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>
          
            </CardHeader>

           <CardBody p={8} pt={-8} marginRight={tabletView && "auto"} ml={tabletView && -10} >
            <HStack flexDirection={tabletView  && "column"} mt={-8}>
            <div >
            <Image src={customer1} boxSize="150px" borderRadius={8} mt={5} size="2xl"/>
            </div>
            <VStack alignItems="start" ml={tabletView? -10: 5}>
            <Heading ml={tabletView && 20}  as="h3" size="md" mt={2} color={toggleProp && "white"}>Mwamba Rollands </Heading> 
      
            <HStack spacing={0} ml={tabletView && 20} >
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            </HStack>
              
            <Text ml={tabletView && 20} color={toggleProp? "white": "#64748b"} >Manager, Zesco</Text>
            </VStack>
            </HStack>
            
            </CardBody>
          </Card>

          
          <Card borderRadius={10}  bg={toggleProp && "#18181b"} cursor="pointer" boxShadow= "20px 0px 20px rgba(0,0,0,0.5)">
            
            <CardHeader p={8}>
            <Text align="start" size="3xl" color={toggleProp? "white": "#64748b"} fontSize="md" >
            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>Ben </span> is a true master of his craft. His ability to understand my vision and bring it to life 
            was impressive. I couldn't be happier with the outcome of our collaboration. Additionally, his friendly demeanor made the entire process<span style={{marginRight: 5}}> enjoyable.</span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>
        
            </CardHeader>

            <CardBody p={8} pt={-8} marginRight={tabletView && "auto"} ml={tabletView && -14}>
            <HStack flexDirection={tabletView  && "column"} mt={-8}>
            <div >
            <Image src={customer2}  boxSize="150px" borderRadius={8} mt={5} size="2xl"/>
            </div>
            <VStack alignItems="start" ml={tabletView? -10: 5}>
            <Heading ml={tabletView && 24} as="h3" size="md" mt={2} color={toggleProp && "white"}>Arthur James </Heading> 
            
            <HStack spacing={0} ml={tabletView && 24}>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStarHalf} color="#F4CE14"/>

            </HStack>
            <Text ml={tabletView && 24} color={toggleProp? "white": "#64748b"} >Marketing Manager, Itel Inc.</Text>
            </VStack>
            </HStack>
            
            </CardBody>
          </Card>


          <Card borderRadius={10}  bg={toggleProp && "#18181b"} cursor="pointer" boxShadow= "20px 0px 20px rgba(0,0,0,0.5)">
            
            <CardHeader p={8}>
            <Text align="start" size="3xl" color={toggleProp? "white": "#64748b"} fontSize="md" >

            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>Working </span> with Ben was a breath of fresh air. His dedication to delivering high-quality results, coupled
            with his excellent communication skills, made him a pleasure to work with. Highly recommended! Furthermore, his
            flexibility in adapting to changes was<span style={{marginRight: 5}}> invaluable.</span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>
      
            </CardHeader>

           <CardBody p={8} pt={-8} marginRight={tabletView && "auto"} ml={tabletView && -7}>
            <HStack flexDirection={tabletView  && "column"} mt={-8}>
            <div>
            <Image src={customer3} boxSize="150px" borderRadius={8} mt={5} size="2xl"/>
            </div>
            <VStack alignItems="start" ml={5}>
            <Heading as="h3" size="md" mt={2} color={toggleProp && "white"}>Mia Williams </Heading> 
            
            <HStack spacing={0} >
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>

            </HStack>
            <Text color={toggleProp? "white": "#64748b"} >Operations, Grew Media</Text>
            </VStack>
            </HStack>
            
            </CardBody>
          </Card>

        </SimpleGrid>


        <SimpleGrid paddingRight={10} paddingLeft={10} columns={{md: 2, lg:2}} gap={8} >
          
          <Card borderRadius={10} bg={toggleProp && "#18181b"} cursor="pointer" boxShadow= "20px 0px 20px rgba(0,0,0,0.5)">
            
            <CardHeader p={8} >
            <Text align="start" size="3xl" color={toggleProp? "white": "#64748b"} fontSize="md" >
            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>I'm</span> so grateful for Ben's expertise and guidance throughout our project. His commitment to delivering on time and
            within budget was commendable. I look forward to partnering with him again. His professionalism and reliability are<span style={{marginRight: 5}}> unmatched.</span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>
          
            </CardHeader>

            <CardBody p={8} pt={-8} marginRight={tabletView && "auto"} ml={tabletView && -5} align={tabletView && "start"}>
            <HStack flexDirection={tabletView  && "column"} mt={-8} >
            < div style={{marginLeft: tabletView && -110}}>
            <Image src={customer5} boxSize="150px" borderRadius={8} mt={5} size="2xl"/>
            </div>
            <VStack alignItems="start" ml={ 5}>
            <Heading as="h3" size="md" mt={2} color={toggleProp && "white"}>Josh Roberts </Heading> 

            <HStack spacing={0} >
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStarHalf} color="#F4CE14"/>
            </HStack>

            <Text color={toggleProp? "white": "#64748b"} >SEO Specialist, E-commerce Ventures LLC.</Text>
            </VStack>
            </HStack>
            
            </CardBody>
          </Card>


            <Card borderRadius={10} bg={toggleProp && "#18181b"} cursor="pointer" boxShadow= "20px 0px 20px rgba(0,0,0,0.5)">
            
            <CardHeader p={8} >
            <Text align="start" size="3xl" color={toggleProp? "white": "#64748b"} fontSize="md" >
            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>Ben's</span> professsionalism and attention to detail set him apart from the rest. His passion for
            his work is evident in the exceptional results he delivers. I wouldn't hesitate to recommended him to anyone. Moreover, his creative solutions exceeded my<span style={{marginRight: 5}}> expectations.</span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>
          
            </CardHeader>

           <CardBody p={8} pt={-8} marginRight={tabletView && "auto"} ml={tabletView && -5} align={tabletView && "start"}>
            <HStack flexDirection={tabletView  && "column"} mt={-8}>
            <Box ml={tabletView && -28}>
            <Image src={customer6} boxSize="150px" borderRadius={8} mt={5} size="2xl"/>
            </Box>
            <VStack alignItems="start" ml={ 5} >
            <Heading as="h3" size="md" mt={2} color={toggleProp && "white"}>James Thompson </Heading> 

            <HStack spacing={0} >
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            </HStack>
              
            <Text color={toggleProp? "white": "#64748b"} >Project Coordinator, Global Solutions Ltd.</Text>
            </VStack>
            </HStack>
            
            </CardBody>
          </Card>

          
          <Card borderRadius={10}  bg={toggleProp && "#18181b"} cursor="pointer" boxShadow= "20px 0px 20px rgba(0,0,0,0.5)">
            
            <CardHeader p={8}>
            <Text align="start" size="3xl" color={toggleProp? "white": "#64748b"} fontSize="md" >
            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>Ben's</span> creativity and technical skills are unmatched. He transformed my ideas into a visually stunning
            website that exceeded my expectations. I could'nt be happier with the end result. His ability to troubleshoot and find solutions was<span style={{marginRight: 5}}> impressive.</span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>
        
            </CardHeader>

            <CardBody p={8} pt={-8} marginRight={tabletView && "auto"} ml={tabletView && -5} align={tabletView && "start"}>
            <HStack flexDirection={tabletView  && "column"} mt={-8}>
            <Box ml={tabletView && -28}>
            <Image src={customer7}  boxSize="150px" borderRadius={8} mt={5} size="2xl"/>
            </Box>
            <VStack alignItems="start" ml={tabletView? 5:5}>
            <Heading as="h3" size="md" mt={2} color={toggleProp && "white"}>Michael Patel </Heading> 
            
            <HStack spacing={0} >
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStarHalf} color="#F4CE14"/>

            </HStack>
            <Text color={toggleProp? "white": "#64748b"} >Senior Analyst, Financial Services Group</Text>
            </VStack>
            </HStack>
            
            </CardBody>
          </Card>


          <Card borderRadius={10}  bg={toggleProp && "#18181b"} cursor="pointer" boxShadow= "20px 0px 20px rgba(0,0,0,0.5)">
            
            <CardHeader p={8}>
            <Text align="start" size="3xl" color={toggleProp? "white": "#64748b"} fontSize="md" >

            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>Working</span> with Ben was a game-changer for my business. His innovative solutions and strategic thinking helped
            us achieve our goals. I can't recommend him highly enough! His dedication to understanding our industry was evident in his <span style={{marginRight: 5}}> work.</span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>
      
            </CardHeader>

           <CardBody p={8} pt={-8} marginRight={tabletView && "auto"} ml={tabletView && -5} align={tabletView && "start"}>
            <HStack flexDirection={tabletView  && "column"} mt={-8}>
            <div style={{marginLeft: tabletView && -110}}>
            <Image src={customer8} boxSize="150px" borderRadius={8} mt={5} size="2xl"/>
            </div>
            <VStack alignItems="start" ml={5}>
            <Heading as="h3" size="md" mt={2} color={toggleProp && "white"}>Alex Smith </Heading> 
            
            <HStack spacing={0} >
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>

            </HStack>
            <Text color={toggleProp? "white": "#64748b"} >Marketing Manager, Tech Innovations Inc</Text>
            </VStack>
            </HStack>
            
            </CardBody>
          </Card>

        </SimpleGrid>

        </Carousel>
      
        
        </VStack>    
    </div>
  )
}


}



export default Testimonial;
