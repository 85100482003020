import React, {createElement, useEffect, useState} from "react";
import {useBreakpointValue,Box, HStack, Alert, AlertIcon, Slide,Center,Heading,Image,VStack, SimpleGrid, Card, Text, Link,CardHeader, CardBody, LinkBox, LinkOverlay, Button, Flex, FormHelperText, CardFooter, Avatar, Tag, TagLabel, Divider, Textarea, Checkbox, Container} from "@chakra-ui/react";
import ReactDOM from "react-dom";
import Pavt from "../Assets/Pavt.jpeg"
import signals from "../Assets/signals.jpg"
import stocks from "../Assets/stocks.jpg"
import Loadspinner from "../Components/Loadspinner";
import { ChakraProvider } from "@chakra-ui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faReply, faUser, faWarning } from "@fortawesome/free-solid-svg-icons";
import {Link as RouterLink} from 'react-router-dom'
import {HashLink} from "react-router-hash-link"
import {Formik, formik, ErrorMessage, Form, Field, validateYupSchema} from 'formik'
import { FormControl, FormErrorMessage,  Input, FormLabel } from "@chakra-ui/react";
import * as Yup from "yup"

import app from "../Components/firebase"
import {getFirestore, collection, addDoc, doc,onSnapshot, serverTimestamp, Timestamp, query, orderBy, getDocs} from "firebase/firestore"
import { ref, onValue, getDatabase} from "firebase/database"







function SignalsBlog({toggleProp, setToggle}){


let dataB = getFirestore(app)
let database = getDatabase(app)

let connectionStatus = ref(database, ".info/connected")

let [online, setOnline] = useState(false)
let [alerterrormessage, setalerterrormessage] = useState(false)
let [isOpenError, setIsOpenError] = useState(false)


  let [initialValues, seInitialValues] = useState({
  comment: "",
  name: "",
  email: "",
  website: "",
  check: false
})

let validationSchema = Yup.object({
  comment: Yup.string().required("Comment is required").min(25, "Minimum characters is 25"),
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email format").required("Email is required"),
  website: Yup.string(),
  check: Yup.string()
})


let comment = document.getElementById("new_comment");
let name = document.getElementById("name")



let [numOfDocs, setNumOfDocs] = useState()
let [replies, setReplies] = useState()



let AvatarComponent2=({commentData, time, replyForm, ReplyButton})=> {

return( <div>
  <ChakraProvider>
  <Box >
    <HStack>
    <Avatar name={commentData.name}  />
      <HStack ml={4} alignItems="start" >
      <FontAwesomeIcon icon={faUser} />
      <Text mt={-1}>{commentData.name}</Text>
      </HStack>
    </HStack>
    <Text mt={4}>{time.toDate().toLocaleString("en-US", {year:"numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric"})} </Text>
    <Text >{commentData.comment}</Text>
    <HStack mt={10} alignItems="start" cursor="pointer">
      <ReplyButton/>
      {replyForm}

    </HStack>

  </Box>
  </ChakraProvider>

</div>  
)
}




let AvatarComponentReplies=({replyCommentsData, replytime})=> {

return( <div>
  <ChakraProvider>
  <Box >
    <HStack>
    <Avatar name={replyCommentsData.name} src={replyCommentsData.email == "bengazy207!@gmail.com" && Pavt}  />
      <HStack ml={4} alignItems="start" >
      <FontAwesomeIcon icon={faUser} />
      <Text mt={-1}>{replyCommentsData.name}</Text>
      </HStack>
    </HStack>
    <Text mt={4}>{replytime.toDate().toLocaleString("en-US", {year:"numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric"})} </Text>
    <Text >{replyCommentsData.comment}</Text>
    <HStack mt={10} alignItems="start" cursor="pointer">

    </HStack>

  </Box>
  </ChakraProvider>

</div>  
)
}



useEffect((e)=>{

  let connectionStatusListeneer = onValue(connectionStatus, (snapshot)=>{
    setOnline(snapshot.val())
  })

  return()=>{
    connectionStatusListeneer()
  }
}, [])



let addComment = async(values, actions) => {

    if(online){      

    try{
      let formCollection = collection(dataB, "signalsComments")
      //let docRef = doc(formCollection) // Reference to a new document in the formCollection
      //let subCollection = collection(docRef, "bitcoinComments")
      await addDoc(formCollection, {...values, timeStamp: serverTimestamp()})
      values.check && localStorage.setItem("UserData", JSON.stringify(values))
      console.log("Form submitted successfully")
      actions.resetForm()
        
    }
    
    catch (error){
      console.error("Error submitting data", error.message)
      actions.setSubmitting(false)
    }
  }

  else{
      setalerterrormessage(true)
      setIsOpenError(true)

      setTimeout((e) => {
      setIsOpenError(false)
    }, 5000);

  }

  }


// Fetching comments from firestore to display it
/*
useEffect((e)=>{

  let userData = localStorage.getItem("UserData")
  if (userData){

  let parseUserData = JSON.parse(userData)
  initialValues.name = parseUserData.name
  initialValues.email = parseUserData.email
  initialValues.website = parseUserData.website   
  }


  let func = ()=>{

  let formCollection = collection(dataB, "signalsComments")
  let querycollection = query(formCollection, orderBy("timeStamp", "desc"))
  // onSnaphot is to listen for changes in the firestore collection
  onSnapshot(querycollection, (snapshot)=>{

  let newContainer = document.createElement("div")
  newContainer.setAttribute("id", "all_comments" )

  setNumOfDocs(snapshot.size)

  snapshot.forEach((doc)=>{
    let commentData = doc.data()
    let time = commentData.timeStamp
    let commentDiv = document.createElement("div")
    commentDiv.setAttribute("class", "comment")
    ReactDOM.render(<AvatarComponent2 commentData={commentData} time={time} />, commentDiv)
    //commentDiv.innerHTML = `<p>${commentData.comment}</p>`
    newContainer.appendChild(commentDiv)
    setLoading(false)
  })

  let oldContainer = document.getElementById("all_comments")
  if(oldContainer){
    oldContainer.parentNode.replaceChild(newContainer, oldContainer)
  }

})

}

  func()

},[])

*/
  

// Fetching comments + RepliesComments from firestore to display it under parentComment

useEffect((e)=>{
  
  let userData = localStorage.getItem("UserData")
  if (userData){

  let parseUserData = JSON.parse(userData)
  initialValues.name = parseUserData.name
  initialValues.email = parseUserData.email
  initialValues.website = parseUserData.website   
  }

  
  let func = ()=>{  

  let formCollection = collection(dataB, "signalsComments")
  let querycollection = query(formCollection, orderBy("timeStamp", "desc"))
  // onSnaphot is to listen for changes in the firestore collection
  onSnapshot(querycollection, async(snapshot)=>{  
  // Iterate through each parent comment to get the number of replies. Snapshot.docs means all the documents in the query
    let totalReplies = 0
    for (let parentDoc of snapshot.docs){
      let parentCommentId = parentDoc.id
      let repliesCollect = collection(dataB, "signalsComments", parentCommentId, "signalsReplyComments") // The docID here means get the collection at this id      
      let repliesQuery = query(repliesCollect)
      let getReplyDocs = await getDocs(repliesQuery)
      totalReplies += getReplyDocs.size
    }
    
    setNumOfDocs(snapshot.size + totalReplies)

  let newContainer = document.createElement("div")
  newContainer.setAttribute("id", "all_comments" )


  snapshot.forEach((doc)=>{
    let commentData = doc.data()
    let time = commentData.timeStamp
    let docID = doc.id
  
    let commentDiv = document.createElement("div")
    commentDiv.setAttribute("class", "comment")

    // Create a container for the parent comment and its replies. This ensures each reply display beneath each parent comment perfectly.
    let commentsWithRepliesContainer = document.createElement("id")
    commentsWithRepliesContainer.setAttribute("class", "all_comments")
    
    // The ReactDOM.render components is unable to take/accept state passed from the parent componenet to it hence we did it here and passed it as a prop to render in it
    let ReplyButton = ()=>{
    return(
      <div>
        <Button colorScheme="facebook" onClick={(e)=> setReplies(docID) } fontWeight="bold" > <FontAwesomeIcon icon={faReply}/> <span style={{paddingLeft: 10}}>Reply</span></Button>
      </div>
    )
    }    
    let replyForm = replies === docID && <ReplyForm toggleProp={toggleProp} commentData={commentData} docID={docID} />
    ReactDOM.render(<AvatarComponent2 commentData={commentData} time={time} docID={docID} ReplyButton={ReplyButton} replyForm={replyForm} />, commentDiv)
    //newContainer.appendChild(commentDiv)
    commentsWithRepliesContainer.appendChild(commentDiv)
    setLoading(false)

    
    // Fetching replies from firestore to display beneath parentComment
    let repliesCollection = collection(dataB, "signalsComments", docID, "signalsReplyComments") // The docID here means get the collection at this id
    let queryRepliesCollection = query(repliesCollection, orderBy("timeStamp", "desc"))

    onSnapshot(queryRepliesCollection, (repliesSnapshot)=>{

      repliesSnapshot.forEach(async(replydoc)=>{
        let replyCommentsData = replydoc.data()
        let replytime = replyCommentsData.timeStamp
        let replyDocID = replydoc.id

        let replyDiv = document.createElement("div")
        replyDiv.setAttribute("class", "reply")
        
        ReactDOM.render(<AvatarComponentReplies replyCommentsData={replyCommentsData} replytime={replytime} replyDocID={replyDocID} />, replyDiv)
        //commentDiv.innerHTML = `<p>${commentData.comment}</p>`
        commentsWithRepliesContainer.appendChild(replyDiv)
      })
    })
        
    //Now append the commentWithRepliesContainer with the main container/ AKA the mothership of container
    newContainer.appendChild(commentsWithRepliesContainer)

  })

  let oldContainer = document.getElementById("all_comments")
  if(oldContainer){
    oldContainer.parentNode.replaceChild(newContainer, oldContainer)
  }

})

}
  func()

},[replies,])




let ReplyForm = ({toggleProp, commentData, docID})=>{
  
let addReplyComment = async(values, actions) => {

  let parentCommentId = docID

    if(online){      

    try{
      let formCollection = collection(dataB, "signalsComments")
      let parentCommentDocRef = doc(formCollection, parentCommentId) // Reference to a new document in the formCollection  //2. Here we included the because we wanted the doc to be at the parent doc id
      let subCollection = collection(parentCommentDocRef, "signalsReplyComments")
      await addDoc(subCollection, {...values, timeStamp: serverTimestamp()})
      values.check && localStorage.setItem("UserData", JSON.stringify(values))
      console.log("Form submitted successfully")
      actions.resetForm()
      setReplies()
    
    }
    
    catch (error){
      console.error("Error submitting data", error.message)
      actions.setSubmitting(false)
    }
  }

  else{
      setalerterrormessage(true)
      setIsOpenError(true)

      setTimeout((e) => {
      setIsOpenError(false)
    }, 5000);

  }

  }
  
  
  
  return(
    <div>
      <VStack ml={-24} mr={4} >        

      <Box mt={20}>
          <HStack margin={5} marginBottom={0} width="100%" justifyContent="space-between" >
          <Heading   as="h2" size={mobileView? "md": (tabletView? "xl": "2xl")}  id="services-section">Reply to {commentData.name}</Heading>
          <Heading as="h2" color="green" size={mobileView? "md": "lg"}  onClick={(e)=> setReplies()} >Cancel reply</Heading>
          </HStack>
        </Box>

        <Text pt={10} textAlign="center" fontSize={mobileView? "xl": "2xl"} style={{margin: 5}} > 
            Your email will not be published. Required fields are marked *
        </Text>

          
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={addReplyComment}
        >

        {(formik) =>(
          
          <Form id="form">
            <FormControl isInvalid={!!formik.errors.comment && formik.touched.comment} >
              <FormLabel htmlFor="comment">Comment <sup style={{marginLeft: 4}}>*</sup></FormLabel>
             <Field as={Textarea} id="new_comment" fontSize={mobileView? "md": "xl"}  name="comment" style={{border: "2px solid black"}} minHeight={250}  placeholder="Message..." />  

              <FormErrorMessage>
              <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
              <ErrorMessage name="comment" />
              </FormErrorMessage>

            </FormControl>

            <FormControl isInvalid={!!formik.errors.name && formik.touched.name} mt={4}>
              <FormLabel htmlFor="name">Name <sup style={{marginLeft: 4}}>*</sup></FormLabel>
              <Field as={Input} fontSize={mobileView? "md": "xl"}  name="name" id="name" style={{border: "2px solid black"}}  /> 
              <FormErrorMessage>
              <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
              <ErrorMessage name="name" />
              </FormErrorMessage>

            </FormControl>
            
            <FormControl isInvalid={!!formik.errors.email && formik.touched.email} mt={4}>
              <FormLabel htmlFor="email">Email <sup style={{marginLeft: 4}}>*</sup></FormLabel>
              <Input as={Field} fontSize={mobileView? "md": "xl"} name="email" id="email" style={{border: "2px solid black"}}  />
              <FormErrorMessage>
              <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
              <ErrorMessage name="email" />
              </FormErrorMessage>

            </FormControl>

            <FormControl isInvalid={!!formik.errors.website && formik.touched.website} mt={4}>
              <FormLabel htmlFor="website">Website </FormLabel>
              <Input as={Field} fontSize={mobileView? "md": "xl"} name="website" id="website" style={{border: "2px solid black"}}  />
              <FormErrorMessage>
              <ErrorMessage name="website" />
              </FormErrorMessage>

            </FormControl>

            <FormControl isInvalid={!!formik.errors.check && formik.touched.check} mt={4}>
  
              <Field as={Checkbox} name="check" id="check" size={mobileView? "md": "lg"} >Save my name, email and website in this browser for the next time I comment.</Field>
            
            </FormControl>

            <Button type="submit" mt="4" width="full" colorScheme="purple" isLoading={formik.isSubmitting}>Post Comment</Button>

          </Form>
        )}
          
        </Formik>
        </VStack>
    
    </div>
  )
}



let mobileView = useBreakpointValue({
  base: true,
  md: false
})


let tabletView = useBreakpointValue({
  md: true, 
  lg: false
})



let [loading, setLoading] = useState(true)


if (loading) {
  return(
    <div> <Loadspinner toggleProp={toggleProp} /></div>
  )
 }

 

  return(
    <div>
        <VStack maxWidth="full" padding={10} alignItems="left" spacing={8} backgroundColor={toggleProp? "#18181b":"white"} color={toggleProp? "white": "black"}  pl={tabletView? 10: (mobileView? 5: 40)} pr={tabletView? 10: (mobileView? 5: 40)} >        
        
          <Center>
          <Heading margin={5} paddingTop={20} marginBottom={0} as="h2" size="2xl" id="3">Conflicting Signals, Erratic Movements</Heading>
          </Center>

        
          <Text fontSize={mobileView? "xl": "2xl"} color={toggleProp? "white": "#64748b"} style={{margin: 5}} > 
          Market movements can be erratic and this reminds me of an interesting column published 
          this week by Joachim Klement, a market economist from Liberum whom I quote regularly in these columns.
          </Text>

          <Card bg={toggleProp && "#18181b"} >

          <CardFooter p={8} pt={-8} pl={-8} >
                <HStack>
                  <Avatar size="lg" src={Pavt} name="Ben"/>
                  <VStack fontSize="xl" alignItems="start" spacing={0} color={toggleProp &&"white"}>
                    <Text margin={0} fontWeight="bold">Ben Asenso</Text>
                    <Text margin={0} >April 18, 2023</Text>
                  </VStack>
                </HStack>
          </CardFooter>
          

          <CardBody pt={4} pl={0} pr={0} >
                <Box overflow="hidden">
                <Image src ={signals} height={mobileView? "sm": "lg"} width="full" objectFit="cover" _hover={{transform: "scale(1.1)", transition:"transform ease-in-out 0.3s"}} alt="Bitcoin jumps to highest since August" />      
                </Box>
                
            <Text fontSize={mobileView? "md": "xl"} color={toggleProp? "white": "#64748b"}  style={{margin: 5, paddingTop: 60}}>  
            In the realm of finance, market movements often exhibit erratic behavior, reminiscent of a rollercoaster ride filled 
            with twists and turns. This volatility prompts reflection on a recent column by Joachim Klement, a market economist from 
            Liberum, whose insights shed light on the complexities of navigating financial landscapes. Klement emphasizes the limitations 
            of traditional financial studies in capturing the nuances of real-world market dynamics, advocating instead for an empirical 
            approach rooted in observation and adaptability. Recognizing investing and economics as intricate social systems, influenced by 
            myriad factors including individual behaviors, information dissemination, biases, and prevailing sentiments, Klement underscores the 
            importance of regularly reassessing one's convictions in light of evolving realities. As we venture into the new year, embracing a mindset 
            of flexibility and continual learning amidst uncertainty could serve as a valuable resolution for investors in 2023.<br/><br/>
            In the realm of corporate news:<br/><br/>
            Tesla, a pioneer in the electric vehicle industry, recently announced a second price reduction for its cars in China, citing intensifying 
            competition in the region's automotive market. With prices now 24 percent lower than their September levels, the move reflects Tesla's strategic
             response to shifting market dynamics and heightened competitive pressures. However, the decision underscores broader challenges facing the Chinese 
             car market and raises questions about Tesla's positioning in this evolving landscape. As investors monitor developments, Tesla's stock experiences 
             a 5.4 percent decline in premarket trading, signaling market reactions to the company's strategic adjustments.<br/><br/>
             Meanwhile, Southwest Airlines grapples with operational challenges following a wave of flight cancellations last month. 
             The airline, committed to ensuring passenger satisfaction and operational efficiency, pledges to conduct a comprehensive review of its operations 
             in response to the incident. However, the cancellations could potentially lead to a significant revenue shortfall, estimated by a union official to 
             be up to $1 billion. Analysts at Raymond James project that the incident may also impact fourth-quarter revenue growth projections, potentially reducing
            them by more than half. As Southwest Airlines navigates through these challenges, investors closely monitor the stock's performance, which registers a 1% 
            decline in pre-market trading, reflecting market sentiments and expectations regarding the airline's operational resilience and strategic response.<br/><br/>
            In the ever-evolving landscape of finance and corporate operations, adaptability, and resilience emerge as essential attributes for investors and businesses alike. 
            As market dynamics continue to evolve and uncertainties persist, embracing a proactive and flexible approach becomes paramount in navigating challenges and seizing 
            opportunities. By staying attuned to emerging trends, leveraging insights from empirical observations, and remaining agile in response to changing realities, investors 
            and companies can position themselves for long-term success amidst the unpredictability of today's markets.
            Moreover, beyond the immediate market concerns, broader macroeconomic trends and geopolitical events also influence investor sentiment and market dynamics. The 
            interconnectedness of global economies and the ripple effects of geopolitical tensions underscore the need for a comprehensive understanding of macroeconomic factors 
            and their implications for investment strategies.<br/><br/>
            Furthermore, technological advancements and innovations continue to reshape industries and disrupt traditional business models. From the rise of artificial intelligence 
            and automation to the emergence of blockchain technology and digital currencies, these innovations present both opportunities and challenges for investors and businesses 
            navigating a rapidly evolving landscape. In this dynamic environment, staying informed and adaptable is essential for success. By embracing a growth mindset and continuously 
            seeking opportunities for learning and improvement, investors and businesses can thrive amidst uncertainty and volatility. As we navigate the complexities of today's markets, 
            resilience, agility, and a forward-thinking approach will be key drivers of long-term success.

            </Text>

          </CardBody>

          <CardFooter pt={0} pl={0} pr={0}>
          <HStack>
                <Tag size={mobileView? "sm": "lg"} transform="scale(1.4)" colorScheme="teal" margin={5} mt={14}  >
                  <TagLabel >Finance</TagLabel>
                </Tag>

              
                </HStack>

          </CardFooter>

        </Card>
        

        <Card bg={toggleProp? "#18181b": "gray.100" } p={mobileView? 5: 20} mb={10} color={toggleProp && "white"} >

        
        

      <h2>{numOfDocs} Comments</h2>
      <div  class="all_comments" id="all_comments">
       
      </div>

    
        
        
      { !replies &&     
        <>
        <Center mt={10}>
          <Heading margin={5} marginBottom={0} as="h2" size={mobileView? "xl": "2xl"}  id="services-section">Leave a Reply</Heading>
        </Center>

        <Text pt={10} textAlign="center" fontSize={mobileView? "xl": "2xl"} color={toggleProp && "white"} style={{margin: 5}} > 
            Your email will not be published. Required fields are marked *
        </Text>

              
        
    
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={addComment}
        >

        {(formik) =>(
          
          <Form id="form">
            <FormControl isInvalid={!!formik.errors.comment && formik.touched.comment} >
              <FormLabel htmlFor="comment">Comment <sup style={{marginLeft: 4}}>*</sup></FormLabel>
             <Field as={Textarea} id="new_comment" fontSize={mobileView? "md": "xl"}  name="comment" style={{border: !toggleProp && "2px solid black"}} minHeight={250}  placeholder="Message..." />  

              <FormErrorMessage>
              <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
              <ErrorMessage name="comment" />
              </FormErrorMessage>

            </FormControl>

            <FormControl isInvalid={!!formik.errors.name && formik.touched.name} mt={4}>
              <FormLabel htmlFor="name">Name <sup style={{marginLeft: 4}}>*</sup></FormLabel>
              <Field as={Input} fontSize={mobileView? "md": "xl"}  name="name" id="name" style={{border: !toggleProp && "2px solid black"}}  /> 
              <FormErrorMessage>
              <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
              <ErrorMessage name="name" />
              </FormErrorMessage>

            </FormControl>
            
            <FormControl isInvalid={!!formik.errors.email && formik.touched.email} mt={4}>
              <FormLabel htmlFor="email">Email <sup style={{marginLeft: 4}}>*</sup></FormLabel>
              <Input as={Field} fontSize={mobileView? "md": "xl"} name="email" id="email" style={{border: !toggleProp && "2px solid black"}}  />
              <FormErrorMessage>
              <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
              <ErrorMessage name="email" />
              </FormErrorMessage>

            </FormControl>

            <FormControl isInvalid={!!formik.errors.website && formik.touched.website} mt={4}>
              <FormLabel htmlFor="website">Website </FormLabel>
              <Input as={Field} fontSize={mobileView? "md": "xl"} name="website" id="website" style={{border: !toggleProp && "2px solid black"}}  />
              <FormErrorMessage>
              <ErrorMessage name="website" />
              </FormErrorMessage>

            </FormControl>

            <FormControl isInvalid={!!formik.errors.check && formik.touched.check} mt={4}>
  
              <Field as={Checkbox} name="check" id="check" size={mobileView? "md": "lg"} >Save my name, email and website in this browser for the next time I comment.</Field>
            
            </FormControl>

            <Button type="submit" mt="4" width="full" colorScheme="purple" isLoading={formik.isSubmitting}>Post Comment</Button>

          </Form>
        )}
          
        </Formik>
        </>
      }

              

      

        </Card>



          
          {alerterrormessage && 
            <Slide in={isOpenError} style={{marginTop: 40}}>
            <Alert status="error" mt={5} >
              <AlertIcon />
              <span style={{color:"black"}}>You are offline. Please check your internet connection and try again.</span>
            </Alert>

            </Slide> }
      

        
        <Heading  marginBottom={0} as="h2" size="xl" id="services-section">Recent articles</Heading>
          
          <Link as={HashLink} width="fit-content" smooth to="/blog/bitcoin-jumps-to-highest-since-august#1" fontSize="xl" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}  color={toggleProp? "white": "#64748b"} style={{marginBottom:-10}} >
          Bitcoin Jumps to Highest Since August
          </Link>

          <Link as={HashLink}smooth to="/blog/the-bears-come-back-to-bother-the-bulls#2" fontSize="xl" width="fit-content" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} color={toggleProp? "white": "#64748b"} style={{marginBottom:-10}} >
          The Bears Come Back to Bother the Bulls
          </Link>

           <Link as={HashLink} smooth to="/blog/conflicting-signals-erratic-movements#3" fontSize="xl" width="fit-content"  _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} color={toggleProp? "white": "#64748b"}  style={{marginBottom:-10}}>
          Conflicting Sinals, Erratic Movements
          </Link>

           <Link as={HashLink} smooth to="/blog/investor-optimism-is-about-to-be-tested#4" fontSize="xl" width="fit-content" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} color={toggleProp? "white": "#64748b"}  mb={20} >
          Investor Optimism is About to Be Tested
          </Link>
      


        </VStack>    
    </div>
  )
}



export default SignalsBlog;
