import React, {useEffect, useState} from "react";
import {useBreakpointValue,Box,Center,HStack,Heading,Image,VStack, SimpleGrid, Card, Text, Link,CardHeader, CardBody, LinkBox, LinkOverlay} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";

import html from "../Assets/html5.png"
import css3 from "../Assets/CSS3.png"
import js from "../Assets/jss.png"
import python from "../Assets/python.png"
import reacts from "../Assets/reacts.png"
import nodejs from "../Assets/nodejs.png"
import boot from "../Assets/boot.png"
import restapi from "../Assets/Rest API.png"
import mysql from "../Assets/mysql.png"
import tailwind from "../Assets/tailwind.png"
import django from "../Assets/django.png"
import github from "../Assets/github.png"
import { css } from "@emotion/react";



function Skills({toggleProp, setToggle, setSelected}){

let [visible, setVisible] = useState(false)
let {ref: ref1, inView: inView1} = useInView({triggerOnce: true})
let {ref: ref2, inView: inView2} = useInView({triggerOnce: true})
let {ref: ref3, inView: inView3} = useInView({triggerOnce: true})
let {ref: ref4, inView: inView4} = useInView({triggerOnce: true})
let {ref: ref5, inView: inView5} = useInView({triggerOnce: true})
let {ref: ref6, inView: inView6} = useInView({triggerOnce: true})
let {ref: ref7, inView: inView7} = useInView({triggerOnce: true})
let {ref: ref8, inView: inView8} = useInView({triggerOnce: true})
let {ref: ref9, inView: inView9} = useInView({triggerOnce: true})
let {ref: ref10, inView: inView10} = useInView({triggerOnce: true})
let {ref: ref11, inView: inView11} = useInView({triggerOnce: true})
let {ref: ref12, inView: inView12} = useInView({triggerOnce: true})



let {ref: refSkills, inView: inViewSkills} = useInView({triggerOnce: false})







let animation = useSpring({
  opacity: visible? 1: 0,
  transform: visible? "tranlateX(0px)" : "translateX(-100px)",
  config: {tension: 200, friction: 20, duration: 1000}
})


useEffect((e)=>{
  
  if(inView1 || inView2||inView3||inView3||inView4||inView4||inView5||inView6||inView7||inView8||inView9||inView10||inView11||inView12){
    setVisible(true)
  }
}, [inView1, inView2, inView3, inView4, inView5, inView6, inView7, inView8, inView9, inView10, inView11, inView12])


useEffect((e)=>{
  if(inViewSkills){
    setSelected("Skills")
  }
}, [inViewSkills])

let mobileView = useBreakpointValue({
  base: true,
  md: false
})


let tabletView = useBreakpointValue({
  md: true, 
  lg: false
})

  
  return(
    <div>
        <VStack maxWidth="full" padding={10} alignItems="left" spacing={8} backgroundColor={toggleProp? "#18181b":"#90acd1"} color="white" >
          
        <Heading as="h2" size="2xl" color="white" id="skills" ref={refSkills}>Skills</Heading>

        <Text fontSize="xl" style={{margin: 5, paddingTop: 20}}>Embark on a journey through my professional toolkit and uncover the diverse range of experiences and capabilities that have fueled my passion for creating exceptional digital experiences. 
        From cutting-edge technologies to strategic insights and methodologies that form the cornerstone of my skill set.
        </Text>

      
            
            <Box width="full"  >
            
            
            <SimpleGrid columns={{base: 2, md: 3, lg:4}} gap={mobileView? 4:8}  ml={mobileView? -5: (tabletView? -10: "")} mr={mobileView? -5: (tabletView? -10: "")} paddingLeft={mobileView? 0:10} paddingRight={mobileView? 0:10} >
              
              <animated.div style={animation} ref={ref1}>
              {/*BoxShadow, First one is horizontal and the second is vertical */}
                  
              <Card borderRadius={10} bg={toggleProp && "#18181b"} cursor="pointer" boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >
              <CardHeader p={8} >
                <Center>
                <Image src ={html} width={mobileView? 16: 20} height={mobileView? 16: 20}/>      
                </Center>
                <Heading mt={5} textAlign="center" fontSize={mobileView && "xl"} color={toggleProp && "white"} >HTML5</Heading>
              </CardHeader>
              
       
              <CardBody p={8} pt={-8} >
              
              <Text size="3xl" textAlign="center" color={toggleProp? "white": "#64748b"} fontWeight="bold" fontSize={!mobileView && "xl"} >
              2 years experience
              </Text>

              </CardBody>
              
            </Card>
            </animated.div>
            

            <animated.div style={animation} ref={ref2}>
            <Card borderRadius={10}  bg={toggleProp && "#18181b"}  cursor="pointer"  boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >    
              <CardHeader p={8} >
                <Center>
                <Image  src ={js}  width={mobileView? 16: 20} height={mobileView? 16: 20}/>
                </Center>
                <Heading mt={5} textAlign="center" fontSize={mobileView && "xl"} color={toggleProp && "white"}>JavaScript</Heading>
              </CardHeader>
              
       
              <CardBody p={8} pt={-8}  >
              
              <Text size="3xl" textAlign="center" color={toggleProp? "white": "#64748b"} fontWeight="bold" fontSize={!mobileView && "xl"} >
              3 years experience
              </Text>

              </CardBody>
            </Card>
            </animated.div>


            <animated.div style={animation} ref={ref3}>
            <Card borderRadius={10} cursor="pointer" bg={toggleProp && "#18181b"}  boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >
              <CardHeader p={8}>
                <Center>
                <Image  src ={python}  width={mobileView? 16: 20} height={mobileView? 16: 20}/>
                </Center>
                <Heading mt={5} textAlign="center" fontSize={mobileView && "xl"} color={toggleProp && "white"} >Python</Heading>
              </CardHeader>
            
       
              <CardBody p={8} pt={-8} >
              
              <Text size="3xl" textAlign="center" color={toggleProp? "white": "#64748b"} fontWeight="bold" fontSize={!mobileView && "xl"} >
              4 years experience
              </Text>

              </CardBody>
            </Card>
            </animated.div>

            
            <animated.div style={animation} ref={ref4}>
            <Card borderRadius={10} cursor="pointer" bg={toggleProp && "#18181b"} boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >
              <CardHeader p={8}>
                <Center>
                <Image src ={reacts}  width={mobileView? 16: 20} height={mobileView? 16: 20}/>
                </Center>
                <Heading mt={5} textAlign="center" fontSize={mobileView && "xl"} color={toggleProp && "white"}>React</Heading>
              </CardHeader>
              
       
              <CardBody p={8} pt={-8} >
              
              <Text size="3xl" textAlign="center" color={toggleProp? "white": "#64748b"} fontWeight="bold" fontSize={!mobileView &&"xl"} >
              2 years experience
              </Text>

              </CardBody>
            </Card>
            </animated.div>

            
            <animated.div style={animation} ref={ref5}>
            <Card borderRadius={10} cursor="pointer" bg={toggleProp && "#18181b"} boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >
              <CardHeader p={8}>
                <Center>
                <Image  src ={nodejs}  width={mobileView? 16: 20} height={mobileView? 16: 20}/>
                </Center>
                <Heading mt={5} textAlign="center" fontSize={mobileView && "xl"} color={toggleProp && "white"}>Node.js</Heading>
              </CardHeader>
              
       
              <CardBody p={8} pt={-8} >
              
              <Text size="3xl" textAlign="center" color={toggleProp? "white": "#64748b"} fontWeight="bold" fontSize={!mobileView && "xl"} >
              1 year experience
              </Text>

              </CardBody>
            </Card>
            </animated.div>

            
            <animated.div style={animation} ref={ref6}>
            <Card borderRadius={10} cursor="pointer" bg={toggleProp && "#18181b"} boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >
              <CardHeader p={8}>
                <Center>
                <Image  src ={css3}  width={mobileView? 16: 20} height={mobileView? 16: 20}/>
                </Center>
                <Heading mt={5} textAlign="center" fontSize={mobileView && "xl"} color={toggleProp && "white"}>CSS3</Heading>
              </CardHeader>
              
       
              <CardBody p={8} pt={-8} >
              
              <Text size="3xl" textAlign="center"  color={toggleProp? "white": "#64748b"} fontWeight="bold" fontSize={!mobileView && "xl"} >
              2 years experience
              </Text>

              </CardBody>
            </Card>
            </animated.div>


            <animated.div style={animation} ref={ref7}>
            <Card borderRadius={10} cursor="pointer" bg={toggleProp && "#18181b"} boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >

              <CardHeader p={8}>
                <Center>
                <Image  src ={boot}  width={mobileView? 16: 20} height={mobileView? 16: 20}/>
                </Center>
                <Heading mt={5} textAlign="center" fontSize={mobileView && "xl"} color={toggleProp && "white"}> Bootstrap</Heading>
              </CardHeader>
          
       
              <CardBody p={8} pt={-8} >
              
              <Text size="3xl" textAlign="center"  color={toggleProp? "white": "#64748b"} fontWeight="bold" fontSize={!mobileView && "xl"} >
              2 years experience
              </Text>

              </CardBody>
            </Card>
            </animated.div>


            
            <animated.div style={animation} ref={ref8}>
            <Card borderRadius={10} cursor="pointer" bg={toggleProp && "#18181b"} boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >

              <CardHeader p={8}>
                <Center>
                <Image  src ={restapi} transform="scale(1.1)"  width={mobileView? 16: 20} height={mobileView? 16: 20}/>
                </Center>
                <Heading mt={5} textAlign="center" fontSize={mobileView && "xl"} color={toggleProp && "white"}> Rest Api</Heading>
              </CardHeader>
          
       
              <CardBody p={8} pt={-8} >
              
              <Text size="3xl" textAlign="center"  color={toggleProp? "white": "#64748b"} fontWeight="bold" fontSize={!mobileView && "xl"} >
              1 year experience
              </Text>

              </CardBody>
            </Card>
            </animated.div>



            <animated.div style={animation} ref={ref9}>
            <Card borderRadius={10} cursor="pointer" bg={toggleProp && "#18181b"} boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >

              <CardHeader p={8}>
                <Center>
                <Image  src ={mysql}  width={mobileView? 36: 36} transform="scale(1.5)"  height={mobileView? 16: 20} />
                </Center>
                <Heading mt={5} textAlign="center" fontSize={mobileView && "xl"} color={toggleProp && "white"}> MySQL</Heading>
              </CardHeader>
          
       
              <CardBody p={8} pt={-8} >
              
              <Text size="3xl" textAlign="center"  color={toggleProp? "white": "#64748b"} fontWeight="bold" fontSize={!mobileView && "xl"} >
              3 years experience
              </Text>

              </CardBody>
            </Card>
            </animated.div>

            
            <animated.div style={animation} ref={ref10}>
            <Card borderRadius={10} cursor="pointer" bg={toggleProp && "#18181b"} boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >

              <CardHeader p={8}>
                <Center>
                <Image  src ={tailwind}  width={mobileView? 16: 20} height={mobileView? 16: 20} />
                </Center>
                <Heading mt={5} textAlign="center" fontSize={mobileView && "xl"} color={toggleProp && "white"}> Tailwind</Heading>
              </CardHeader>
          
       
              <CardBody p={8} pt={-8} >
              
              <Text size="3xl" textAlign="center"  color={toggleProp? "white": "#64748b"} fontWeight="bold" fontSize={!mobileView && "xl"} >
              1 year experience
              </Text>

              </CardBody>
            </Card>
            </animated.div>



            <animated.div style={animation} ref={ref11}>
            <Card borderRadius={10} cursor="pointer" bg={toggleProp && "#18181b"} boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >

              <CardHeader p={8}>
                <Center>
                <Image  src ={django}  width={mobileView? 32: 32}  height={mobileView? 16: 20} />
                </Center>
                <Heading mt={5} textAlign="center" fontSize={mobileView && "xl"} color={toggleProp && "white"}>Django</Heading>
              </CardHeader>
          
       
              <CardBody p={8} pt={-8} >
      
              <Text size="3xl" textAlign="center"  color={toggleProp? "white": "#64748b"} fontWeight="bold" fontSize={!mobileView && "xl"} >
              1 year experience
              </Text>

              </CardBody>
            </Card>
            </animated.div>


            <animated.div style={animation} ref={ref12}>
            <Card borderRadius={10} cursor="pointer" bg={toggleProp && "#18181b"} boxShadow= "20px 0px 20px rgba(0,0,0,0.5)" >
              <CardHeader p={8}>
                <Center>
                <Image  src ={github} style={{WebkitFilter: toggleProp && "invert(100%"}}  width={mobileView? 16: 20} height={mobileView? 16: 20}/>
                </Center>
                <Heading mt={5} textAlign="center" fontSize={mobileView && "xl"} color={toggleProp && "white"}>Github</Heading>
              </CardHeader>
              
       
              <CardBody p={8} pt={-8} >
              
              <Text size="3xl" textAlign="center" color={toggleProp? "white": "#64748b"} fontWeight="bold" fontSize={!mobileView && "xl"} >
              2 years experience
              </Text>

              </CardBody>
            </Card>
            </animated.div>

            

         
          

          

          

          

          


            </SimpleGrid>

           
             

      
             </Box>




        </VStack>    
    </div>
  )
}



export default Skills;
