import React, {useEffect, useState} from "react";
import {useBreakpointValue,Box, Container, HStack, Center,Heading,Image,VStack, SimpleGrid, Card, Text, Link,CardHeader, CardBody, LinkBox, LinkOverlay, IconButton, AvatarGroup, Avatar} from "@chakra-ui/react";

import {useSpring ,animated} from "react-spring"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Popover, PopoverTrigger, PopoverContent, PopoverArrow,PopoverBody } from "@chakra-ui/react";
import digital from "../Assets/certified digital marketing freelancer.png"
import hubspot from "../Assets/HubSpot-Certified-Ben.png"
import BIDA from "../Assets/BIDA-Certified-Ben.png"
import CMSA from "../Assets/CMSA-Certified-Ben.png"
import CBCA from "../Assets/CBCA-Certified-Ben.png"
import experience from "../Assets/work experience.png"
import retail from "../Assets/retail.jpg"
import blockchain from "../Assets/blockchain.jpg"
import financial from "../Assets/financial.jpg"
import estate from "../Assets/Real estate.jpg"
import {IconButton as IconB} from "@mui/material";










function About({toggleProp, setToggle}){

let [info, setInfo] = useState(false)
let [color, setColor] = useState(false)
let [color2, setColor2] = useState(false)
let [color3, setColor3] = useState(false)
let [color4, setColor4] = useState(false)



let onClose =(e)=>{
  setInfo(false)
}

let[visible, setVisible] = useState(false)
let[visible2, setVisible2] = useState(false)



let animation = useSpring ({
  to: {translateY:visible&& 10},
  from: { translateY:visible && 0},
  loop: {reverse: true},
  config: {duration: 1000}
})

let animation2 = useSpring ({
  to: {translateY:visible &&-10},
  from: { translateY:visible && 0},
  loop: {reverse: true},
  config: {duration: 1000}
})


useEffect((e)=>{
setVisible(true)
}, [visible])




let mobileView = useBreakpointValue({
  base: true,
  md: false
})


let tabletView = useBreakpointValue({
  md: true, 
  lg: false
})



if(mobileView){
  return(
    <div >
       <VStack maxWidth="full" padding={10} alignItems="left" spacing={8} backgroundColor={toggleProp? "#18181b":"#2A4365"} color="white" >
          
        <Heading as="h2" size="2xl" color="white" id="about" >About Me</Heading>

      
        <Text fontSize="xl" fontWeight="bold" style={{paddingTop: 20}}>I'm passionate about crafting immersive digital experiences that captivates audiences
        and drive meaningful engagement. With a blend of creativity, technical expertise and a relentless pursuit of innovation, I thrive on pushing the boundaries 
        of what's possible in the ever-evolving world of web development.
        </Text>
    
        <Text size="3xl" color={toggleProp? "white": "white"} fontSize="lg" >
             Fuelled by curiosity for emerging technologies and a commitment to excellence, I'm constantly honing my skills to stay ahead of the curve.
             Whether it's mastering the latest frameworks, delving into data analysis or refining my design aesthetic, I approach every project with enthusiasm and a thirst
             for knowledge.
             But beyond the code, I'm a firm believer in the power of collaboration and communication. I understand that great digital experiences are not just about pixels and
             lines of code, they're about connecting with audiences on a deeper level, understanding their needs and delivering solutions that resonate. <br/><br/>
             When I'm not crafting code or brainstrorming the next big idea, you'll find me exploring the great outdoors, indulging in a good book or seeking inspiration from art and culture.
             I believe that a well-rounded perspective enriches my work and fuels my creativity.
             If you're looking to build a stunning website, optimize your digital presence or embark on a transformative digital journey, I'm here to bring your vision to life. Let's collaborate, innovate and create something extraordinary together.
        </Text>


        <Center>
          <VStack>
            <Popover
            isOpen={info}
            onClose={onClose}
            arrowSize={15}
            size="md"
            >
              <PopoverTrigger>
              <IconButton as={IconB} onClick={(e)=> setInfo(true)} children={<FontAwesomeIcon icon={faInfoCircle} size="2xl" />} isRound size="sm" /> 
              </PopoverTrigger>

              <PopoverContent bgColor="purple">
              <PopoverArrow  bgColor="purple" />
                <PopoverBody color="white" align="center" p={4} >
                References and Certification papers can be provided upon request. 
                For authenticity purposes only.
                </PopoverBody>
              </PopoverContent>
            </Popover>

        <Heading mt={5} color="white">Qualifications</Heading>        
        </VStack>

       </Center>  

      
          <VStack>
        <HStack width={"100%"} justifyContent="space-between">
          <VStack>
           <animated.div style={animation}>
          <Image src={digital} boxSize="80px" alt="certified digital marketing freelancer" />
          </animated.div>
          <Text align="center" fontSize="sm" fontWeight="bold" style={{margin: 5, paddingTop: 20}}>
            Certified Digital<br/> Marketer by Google
          </Text>
          </VStack>

          <VStack>
          <animated.div style={animation2}>
          <Image src={hubspot} width= {20} transform="scale(1.2)" alt="Hubspot Certified (Ben)"/>
          </animated.div>
          <Text align="center" fontSize="sm" fontWeight="bold" style={{margin: 5, paddingTop: 20}}>
            HubSpot Certified
          </Text>
          </VStack>

        </HStack>

        <HStack mt={4} width={"100%"} justifyContent="space-between">
          <VStack>
          <animated.div style={animation}>
          <Image src={BIDA} boxSize="100px" alt="web developer"/>
          </animated.div>
          <Text align="center" fontSize="sm" fontWeight="bold" style={{margin: 5, paddingTop: 20}}>
            BIDA Certified
          </Text>
          </VStack>

          <VStack>
          <animated.div style={animation2}>
          <Image src={CMSA} boxSize="100px" alt="web developer"/>
          </animated.div>
          <Text align="center" fontSize="sm" fontWeight="bold" style={{margin: 5, paddingTop: 20}}>
            CMSA Certified
          </Text>
          </VStack>
        </HStack>

        <HStack  mt={4} width={"100%"} justifyContent="space-between">
          <VStack>
          <animated.div style={animation}>
          <Image src={CBCA} boxSize="100px" alt="web developer" />
          </animated.div>
          <Text align="center" fontSize="sm" fontWeight="bold" style={{margin: 5, paddingTop: 20}}>
            CBCA Certified
          </Text>
          </VStack>

          <VStack >
          <animated.div style={animation2}>
          <Image src={experience} boxSize="80px" alt="work experience"/>
          </animated.div>
          <Text align="center" fontSize="sm" fontWeight="bold" style={{margin: 5, paddingTop: 20}}>
            Years of Relevant Work<br/> Experience
          </Text>
          </VStack>
        </HStack>

        </VStack>

  
    

        
        <Heading mt={5} textAlign="center" color="white">Industries</Heading>        

          <Center>
          <HStack width="100%" justifyContent="space-between" >
            <VStack>
            <Avatar src={blockchain} name="Blockchain" borderWidth={2} _hover={{transform: "scale(1.2)", transition: "transform 0.3s ease-in-out"}} filter={!color && "grayscale(100%)"} boxSize="120px" onMouseEnter={(e)=> setColor(true)} onMouseLeave={(e)=> setColor(false)}/>
            <Text fontSize="xl" fontWeight="bold" style={{margin: 5, paddingTop: 20}}>
            Blockchain
            </Text>
            </VStack>

            <VStack>
            <Avatar src={estate} name="Real Estate" borderWidth={2} _hover={{transform: "scale(1.2)", transition: "transform 0.3s ease-in-out"}} filter={!color2 && "grayscale(100%)"} boxSize="120px" onMouseEnter={(e)=> setColor2(true)} onMouseLeave={(e)=> setColor2(false)} />
            <Text fontSize="xl" fontWeight="bold" style={{margin: 5, paddingTop: 20}}>
            Real Estate
            </Text>
            </VStack>
          </HStack>
          
          </Center>
      

         
          <Center  paddingBottom={10}>
          <HStack alignItems="start" width="100%" justifyContent="space-between" >
            <VStack>
            <Avatar src={financial} name="financial" borderWidth={2} _hover={{transform: "scale(1.2)", transition: "transform 0.3s ease-in-out"}} filter={!color3 && "grayscale(100%)"} boxSize="120px" onMouseEnter={(e)=> setColor3(true)} onMouseLeave={(e)=> setColor3(false)} />
            <Text fontSize="xl" fontWeight="bold" style={{margin: 5, paddingTop: 20}}>
            Financial <br/> Services
            </Text>
            </VStack>

            <VStack>
            <Avatar src={retail} name="retail" borderWidth={2} _hover={{transform: "scale(1.2)", transition: "transform 0.3s ease-in-out"}} filter={!color4 && "grayscale(100%)"} boxSize="120px" onMouseEnter={(e)=> setColor4(true)} onMouseLeave={(e)=> setColor4(false)}/>
            <Text fontSize="xl" fontWeight="bold" style={{margin: 5, paddingTop: 20}}>
            Retail
            </Text>
            </VStack>
          </HStack>

          </Center>

          

        
        

        </VStack>   

    </div>
  )
}



else{

  
  return(
    <div >
        <VStack maxWidth="full" padding={10} alignItems="left" spacing={8} backgroundColor={toggleProp? "#18181b":"#2A4365"} color="white" >  
          
        <Heading as="h2" size="2xl" color="white" id="about" >About Me</Heading>

      
        <Text fontSize="xl" fontWeight="bold" style={{margin: 5, paddingTop: 20}}>I'm passionate about crafting immersive digital experiences that captivates audiences
        and drive meaningful engagement. With a blend of creativity, technical expertise and a relentless pursuit of innovation, I thrive on pushing the boundaries 
        of what's possible in the ever-evolving world of web development.
        </Text>
    
        <Text size="3xl" color={toggleProp? "white": "white"} fontSize="lg" >
             Fuelled by curiosity for emerging technologies and a commitment to excellence, I'm constantly honing my skills to stay ahead of the curve.
             Whether it's mastering the latest frameworks, delving into data analysis or refining my design aesthetic, I approach every project with enthusiasm and a thirst
             for knowledge.
             But beyond the code, I'm a firm believer in the power of collaboration and communication. I understand that great digital experiences are not just about pixels and
             lines of code, they're about connecting with audiences on a deeper level, understanding their needs and delivering solutions that resonate. <br/><br/>
             When I'm not crafting code or brainstrorming the next big idea, you'll find me exploring the great outdoors, indulging in a good book or seeking inspiration from art and culture.
             I believe that a well-rounded perspective enriches my work and fuels my creativity.
             If you're looking to build a stunning website, optimize your digital presence or embark on a transformative digital journey, I'm here to bring your vision to life. Let's collaborate, innovate and create something extraordinary together.
        </Text>


        <Center>
          <VStack>
            <Popover
            isOpen={info}
            onClose={onClose}
            arrowSize={15}
            size="md"
            >
              <PopoverTrigger>
              <IconButton as={IconB} onClick={(e)=> setInfo(true)} children={<FontAwesomeIcon icon={faInfoCircle} size="2xl" />} isRound size="sm" /> 
              </PopoverTrigger>

              <PopoverContent bgColor="purple">
              <PopoverArrow  bgColor="purple" />
                <PopoverBody color="white" align="center" fontSize="lg" p={4} >
                References and Certification papers can be provided upon request. 
                For authenticity purposes only.
                </PopoverBody>
              </PopoverContent>
            </Popover>

        <Heading mt={5} color="white">Qualifications</Heading>        
        </VStack>

        </Center>

        <Center>
        <HStack width={"100%"} justifyContent="space-between">
          <VStack>
           <animated.div style={animation}>
           <Image src={digital} boxSize={tabletView? "60px": "100px"} />
          </animated.div>
          <Text align="center" fontSize={tabletView? "sm": "xl"} fontWeight="bold" style={{margin: 5, paddingTop: 20}}>
            Certified Digital<br/> Marketer by Google
          </Text>
          </VStack>

          <VStack>
          <animated.div style={animation2}>
          <Image src={hubspot} width={tabletView? 20:40} />
          </animated.div>
          <Text align="center" fontSize={tabletView? "sm": "xl"}  fontWeight="bold" style={{margin: 5, paddingTop: 20}}>
            HubSpot Certified
          </Text>
          </VStack>

          <VStack>
          <animated.div style={animation}>
          <Image src={BIDA} boxSize={tabletView? "80px": "120px"} />
          </animated.div>

          <Text align="center" fontSize={tabletView? "sm": "xl"}  fontWeight="bold" style={{margin: 5, paddingTop: 20}}>
            BIDA Certified
          </Text>
          </VStack>

          <VStack>
          <animated.div style={animation2}>
          <Image src={CMSA} boxSize={tabletView? "80px": "120px"} />
          </animated.div>
          <Text align="center" fontSize={tabletView? "sm": "xl"} fontWeight="bold" style={{margin: 5, paddingTop: 20}}>
            CMSA Certified
          </Text>
          </VStack>

          <VStack>
          <animated.div style={animation}>
          <Image src={CBCA} boxSize={tabletView? "80px": "120px"} />
          </animated.div>
          <Text align="center" fontSize={tabletView? "sm": "xl"} fontWeight="bold" style={{margin: 5, paddingTop: 20}}>
            CBCA Certified
          </Text>
          </VStack>

          <VStack>
          <animated.div style={animation2}>
          <Image src={experience} boxSize={tabletView? "60px": "100px"} />
          </animated.div>
          <Text align="center" fontSize={tabletView? "sm": "xl"} fontWeight="bold" style={{margin: 5, paddingTop: 20}}>
            Years of Relevant Work<br/> Experience
          </Text>
          </VStack>

        </HStack>

        </Center>

  

        <Heading mt={5} textAlign="center" color="white">Industries</Heading>        

        <Center paddingBottom={10}>
               
          <HStack width={tabletView? "100%": "55%"} justifyContent="space-between">
            <VStack>
            <Avatar src={blockchain} name="Blockchain" borderWidth={2} _hover={{transform: "scale(1.2)", transition: "transform 0.3s ease-in-out"}} filter={!color && "grayscale(100%)"} boxSize="120px" onMouseEnter={(e)=> setColor(true)} onMouseLeave={(e)=> setColor(false)}/>
            <Text fontSize="xl" fontWeight="bold" style={{margin: 5, paddingTop: 20}}>
            Blockchain
            </Text>
            </VStack>

            <VStack>
            <Avatar src={estate} name="Real Estate" borderWidth={2} _hover={{transform: "scale(1.2)", transition: "transform 0.3s ease-in-out"}} filter={!color2 && "grayscale(100%)"} boxSize="120px" onMouseEnter={(e)=> setColor2(true)} onMouseLeave={(e)=> setColor2(false)} />
            <Text fontSize="xl" fontWeight="bold" style={{margin: 5, paddingTop: 20}}>
            Real Estate
            </Text>
            </VStack>

            <VStack>
            <Avatar src={financial} name="financial" borderWidth={2} _hover={{transform: "scale(1.2)", transition: "transform 0.3s ease-in-out"}} filter={!color3 && "grayscale(100%)"} boxSize="120px" onMouseEnter={(e)=> setColor3(true)} onMouseLeave={(e)=> setColor3(false)} />
            <Text fontSize="xl" fontWeight="bold" style={{margin: 5, paddingTop: 20}}>
            Financial Services
            </Text>
            </VStack>

            <VStack>
            <Avatar src={retail} name="retail" borderWidth={2} _hover={{transform: "scale(1.2)", transition: "transform 0.3s ease-in-out"}} filter={!color4 && "grayscale(100%)"} boxSize="120px" onMouseEnter={(e)=> setColor4(true)} onMouseLeave={(e)=> setColor4(false)}/>
            <Text fontSize="xl" fontWeight="bold" style={{margin: 5, paddingTop: 20}}>
            Retail
            </Text>
            </VStack>

    
          </HStack>
   
        </Center>





        </VStack>    
    </div>
  )
}

}


export default About;
